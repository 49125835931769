.start {
    &__headder {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: start;
    }
    &__category {
        display:flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;

        &__tile {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: space-between;
            flex-shrink: 0;

            background: #142e48;
            box-shadow: 0px 0px 15px black;
            border-radius: 20px;
            font-size: 1.3rem;
            font-weight: bold;

            width: 150px;
            height: 150px;

            margin: 1rem;
            padding: 0.5rem;

            &:hover {
            background: #1c5d9e;
            box-shadow: 0px 0px 15px #1c5d9e;
            }

            img {
                display: block;
                min-height: 0;
                flex-grow: 1;
                max-width: 100%;
            }
        }
    }
}