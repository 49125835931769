.ig {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;

    padding-top: 0.2em;

    &__grey {
        select {
            background-color: #999;
        }
    }

    &__address-box {
        width: 200px;
        height: 91px;

        border: none;
        border-radius: 5px;
        background-color: #eee;
    }


    &__text-input {
        width: 70px;
        padding-left: 0.3em;

        border: none;
        border-radius: 0.25rem;
        background-color: #eee;

        &--long {
            width: 120px;
        }
        &--small {
            width: 40px;
            text-align: center;
        }
    }

    &__preview-table {
        font-size: 0.8rem;
        font-weight: lighter;
        color: rgb(150, 150, 150);

        &__selected {
            color: rgb(0, 240, 208);
        }

        tr {
            font-weight: normal;
            &:first-of-type {
                font-weight: bold;
            }
            &:hover {
                color: white;
            }
        }
        th {
            min-width: 30px;
            font-weight: inherit;

            &:nth-of-type(3) {
                max-width: 200px;
            }
            svg {
                height: 1em;
            }
        }

    }
}