html {
  height: 100%;
  width: 100%;
}

body {
  background-color: #282c34;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  height: 100%;
  width: 100%;

  a {
    color: inherit;
  }
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  text-align: center;

  &__headder {
    height: 100px;
    border-bottom: solid 1px;

    background-color: #142e48;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 10px;

    & img {
      height: 70%;
      margin-right: 50px;
    }
    & svg {
      height: 70%;
      margin-right: 20px;
    }
  }

  &__body {
    position: absolute;
    top: 100px;
    bottom: 0;
    width: 100%;
  }
}

.button {
  background-color: #ba16c9;
  border-radius: 2em;
  text-align: center;

  padding: 0.2em 1em;
  margin: 0.5em;
  &:hover {
    background-color: #70037a;
  }

  &--file {
    background-color: #329b01;

    input[type="file"] {
      display: none;
    }

    &:hover {
      background-color: #247200;
    }
  }

  &--big {
    padding: 0.5em 2em;
    font-size: 1.5em;
    font-weight: 600;
  }

  &--tiny {
    font-size: 0.7em;
  }

  &--inline {
    display: inline-block;
  }

  &--no-button-style {
    border: none;
    color:white;
  }
}

.page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;

  &--center {
    justify-content: center;
  }

  h2,
  h4,
  h5 {
    margin: 0 0 0.3em 0;
  }
  h3 {
    margin: 0.1em 0 0.3em 0;
  }

  select,
  input,
  label {
    margin-bottom: 0.2em;
  }

  select,
  input {
    margin-left: 0.2em;
  }
  &__seperator {
    width: 100%;
    height: 1px;
    background: white;
    margin: 0.1em 0 0.2em 0;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    &--nowrap {
      flex-wrap: nowrap;
    }
    &--center {
      align-items: center;
    }
  }
  &--small-text {
    font-size: 0.8rem;
  }
  &__column {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    background: #142e48;
    box-shadow: 0px 0px 15px black;
    border-radius: 10px;
    padding: 0.3em;
    margin: 0.5em;

    &--big {
      width: 100%;
      max-width: 800px;
    }

    &--preview {
      min-width: 30%;
      flex-grow: 2;
      background: none;
      box-shadow: none;
      border-radius: 0;
    }

    &--small-sub-col {
      align-items: flex-start;
      font-size: 0.8rem;
      background: none;
      box-shadow: none;
      border-radius: 0;
      margin: 0.1em 0.5em;
    }
    &--left {
      min-width: 200px;
      align-items: flex-start;
    }
    &--left-aligned {
      justify-content: flex-start;
    }
  }

  &--big-text {
    width: 100%;

    display: inherit;
    flex-flow: inherit;
    align-items: inherit;
    justify-content: inherit;

    font-size: 1.3rem;
    line-height: 1.3rem;

    input[type=number] {
      width: 3.2rem;
      font-size: 1.4rem;
      line-height: 1.2rem;
      text-align: center;

      margin-top: 0.2rem;
      
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    
    input[type=checkbox] {
      height: 1.3rem;
      width: 1.3rem;
      margin-top: 0.4rem;
    }

    input[type=text] {
      padding: 0.2rem;
      margin-top: 0.4rem;
    }
    input {
      box-shadow: #00000070 0.1rem 0.1rem 0.4rem;
      border: none;
      border-radius: 0.1rem;
    }
  }
  & --small-text {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
  background: #00000070;
  display: flex;
  align-items: center;
  justify-content: center;
}
