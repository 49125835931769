.days-table {
    font-size: 0.9em;
    margin-top: 0.5rem;
    td {
        width: 2rem;
    }
}
.preview-table {
    color: #aaa;
    font-size: 0.9em;
    td {
        min-width: 2rem;
        padding: 0 0.5rem;
    }
}
.preview-data {
    color: black;
    background-color: #bbb;
    font-weight: 600;
    padding: 0.2rem;
    margin: 0.2rem;
    border-radius: 0.2rem;
    box-shadow: #000000A0 0.1rem 0.1rem 0.8rem;

    &--positive {
        color: white;
        background-color: #059719
    }
    &--negative {
        color: white;
        background-color: #9c1504
    }
}